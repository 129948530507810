<template>
<div>
<Loading v-if="loading"></Loading>
  <div v-if="job && !loading">
        <!-- ======= Breadcrumbs ======= -->
    <section class="breadcrumbs">
      <div class="container">

        <ol>
          <li><a @click="routeTo('home')">Home</a></li>
          <li><a >Opportunities</a></li>
          
        </ol>
        <h2>Opportunities</h2>

      </div>
    </section><!-- End Breadcrumbs -->
   
    <!-- End Breadcrumbs -->
    <section class="inner-page portfolio-details">
      <div class="container">
        <div class="row gy-4">
          <div class="col-lg-8">
            <div class="portfolio-description">
              <h2>{{ job.position }}</h2>
              
              <p class="htmlGenerated"  v-html="job.job_description"></p>
            </div>
            <div class="portfolio-description">
              <h2 v-if="job.job_requirement">Job requirement</h2>
              <p class="htmlGenerated"  v-html="job.job_requirement"></p>
              
            </div>
          </div>

          <div class="col-lg-4">
            <div class="portfolio-info ">
              <h3>Details</h3>
              <ul>
                <li><strong>Title</strong>: {{ job.position }}</li>
                
                <li>
                  <strong>Employment Type</strong>: {{ job.employment_type }}
                </li>
                <li>
                  <strong>Location</strong>: {{ job.location }}
                </li>
                <li>
                  <strong>Deadline</strong>:
                   <time datetime="2020-01-01">{{ job.actualDate }}</time>
                   
                </li>
              </ul>
              
            </div>
          </div>
          <b-modal
            id="modal-xl"
            size="xl"
            title="Extra Large Modal"
            ok-only
            ok-title="Cancel"
            hide-header
          >
            <h3 class="text-center">Apply For Job</h3>
            <b-input v-model="userForm.full_name" placeholder="Full Name">
            </b-input>
            <p v-if="userFormError.full_name" class="error-message text-center">
              Full Name can't be empty
            </p>

            <p>
              <br />
              <b-input v-model="userForm.age" type="number" placeholder="Age">
              </b-input>
            </p>

            <p v-if="userFormError.age" class="error-message text-center">
              Age can't be empty
            </p>

            <p>
              <br />
              <b-input
                v-model="userForm.years_of_experience"
                type="number"
                placeholder="Years of experience"
              >
              </b-input>
            </p>

            <p
              v-if="userFormError.years_of_experience"
              class="error-message text-center"
            >
              Years of experience can't be empty
            </p>

            <p>
              <br />
              <b-input
                v-model="userForm.regional_experience"
                placeholder="Country/Regional Experience"
              >
              </b-input>
            </p>

            <p
              v-if="userFormError.regional_experience"
              class="error-message text-center"
            >
              Regional experience can't be empty
            </p>

            <p>
              <br />
              <label for="">Resume</label>
              <ImagPicker
                :imageData="{ maxNoImg: 1, imageNo: 'Resume' }"
              ></ImagPicker>
            </p>

            <p v-if="userFormError.resume" class="error-message text-center">
              Resume can't be empty
            </p>

            <p>
              <br />
            </p>

            <p class="text-center">
              <b-button variant="success" @click="submitResume"
                >Submit</b-button
              >
            </p>
          </b-modal>
        </div>
      </div>
    </section>
    <div></div>
  </div></div>
</template>
<script>
import Vue from "vue";
import Loading from './container/Loading.vue'
import { getFieldById, postFields } from "../assets/js/service";
import ImagPicker from "../web-admin/Common/ImageUpload.vue";
export default {
   metaInfo: {
    // Children can override the title.
    title: 'Careers',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: '%s ← First Consult',
    // Define meta tags here.
     metaInfo: {
    // Children can override the title.
    title: 'Careers page',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: '%s ← First Consult',
    // Define meta tags here.
    meta: [
{property:"og:site_name" ,content:"First Consult Careers"},
      {property:"og:title" ,content:"First Consult Careers"},
      {property:"og:description" ,content:"First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation."},
      {property:"og:image" ,content:"http://firstconsultet.com/img/favicons.fb3e6239.png"},
      {property:"og:url" ,content:"https//www.firstconsulet.com/career"},


      {name:"twitter:title" ,content:"First Consult Careers"},
      {name:"twitter:description" ,content:"First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation."},
      {name:"twitter:image" ,content:"http://firstconsultet.com/img/favicons.fb3e6239.png"},
      {property:"twitter:url" ,content:"https//www.firstconsulet.com/career"},
      {name:"twitter:card" ,content:"summary"},

      {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
      {name: 'description', content: 'First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation.'}
    ],
    script: [
                { src: 'https://platform.twitter.com/widgets.js', async: true },
            ],
  },
  },
  components: {
    ImagPicker,
    Loading
  },
    watch:{
     "$store.state.loading": {
      handler() {
        this.loading = this.$store.state.loading;
        
      },
     }
    },
  data() {
    return {
      job: null,
      loading:false,
      userFormError: {
        
        full_name: null,
        age: null,
        years_of_experience: null,
        regional_experience: null,
        resume: null,
      },
      userForm: {
        careerId: null,
        full_name: null,
        age: null,
        years_of_experience: null,
        regional_experience: null,
        resume: null,
      },
    };
  },
  mounted() {
    
    if (this.$store.state.jobData) {
      this.job = this.$store.state.jobData;
      this.userForm.careerId = this.job.id;
    } else if(this.$route.params.id){
      this.$store.commit('set',['loading',true]);
      this.userForm.careerId = this.$route.params.id;
      getFieldById('careers',this.$route.params.id,false).then(resp=>{
        this.job = resp.data;
        this.$store.commit('set',['loading',false]);
      })
    }else {
      this.$router.go(-1);
    }
  },
  methods: {
    submitResume() {
      if (!this.userForm.full_name) {
        this.userFormError.full_name = true;
      } else {
        this.userFormError.full_name = false;
      }
      if (!this.userForm.age) {
        this.userFormError.age = true;
      } else {
        this.userFormError.age = false;
      }
      if (!this.userForm.years_of_experience) {
        this.userFormError.years_of_experience = true;
      } else {
        this.userFormError.years_of_experience = false;
      }
      if (!this.userForm.regional_experience) {
        this.userFormError.regional_experience = true;
      } else {
        this.userFormError.regional_experience = false;
      }
      if (!this.$store.state.filePDF || this.$store.state.filePDF.length == 0) {
        this.userFormError.resume = true;
      } else {
        this.userFormError.resume = false;
      }
      if (
        this.$store.state.filePDF &&
        this.$store.state.filePDF.length != 0 &&
          this.userForm.full_name &&
          this.userForm.age &&
          this.userForm.years_of_experience &&
          this.userForm.regional_experience
      ) {
        let token = localStorage.getItem("token");
       this.userForm.resume = this.$store.state.filePDF;
        postFields("jobSeekers", this.userForm, token).then((resp) => {
          this.userForm = {
            full_name: null,
            age: null,
            years_of_experience: null,
            regional_experience: null,
            resume: null,
          };
          Vue.$toast.success("Successful", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,

            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.$bvModal.hide("modal-xl");
        });
      }
    },
  },
};
</script>
<style>
</style>